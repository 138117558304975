export default {
  props: {
    title: {
      type: [String, Boolean],
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: function () {
    return {};
  },
};
